import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContentFeaturedPoints from '../components/ContentFeaturedPoints';
import ModularBlocks from '../components/ModularBlocks';
import Testimonials from '../components/Testimonials';
import CallbackCta from '../components/CallbackCta';
import { translateString } from '../utils';

const CaseStudyPageTemplate = ({
  data: { datoCmsCaseStudy },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    subtitle,
    featuredImage,
    introduction,
    whatWeDid,
    modularBlocks,
    testimonialQuote,
    testimonialName,
    testimonialInformation,
  } = datoCmsCaseStudy;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsCaseStudy}
      verticalText={translateString('Case Studies', locale)}
    >
      <main>
        <Banner
          overline={translateString('Case Study', locale)}
          heading={title}
          text={subtitle}
          image={featuredImage}
          locale={locale}
        />
        <ContentFeaturedPoints
          contentHeading={translateString('Introduction', locale)}
          content={introduction}
          itemsHeading={translateString('What We Did', locale)}
          items={whatWeDid}
        />
        <ModularBlocks items={modularBlocks} locale={locale} />
        {testimonialQuote && (
          <Testimonials
            heading={translateString('Feedback from the Client', locale)}
            items={[
              {
                quote: testimonialQuote,
                name: testimonialName,
                information: testimonialInformation,
              },
            ]}
          />
        )}
      </main>
      <CallbackCta locale={locale} />
    </Layout>
  );
};

export const CaseStudyPageTemplateQuery = graphql`
  query CaseStudyPageTemplateQuery($id: String!) {
    datoCmsCaseStudy(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      featuredImage {
        ...SubpageBannerImageFragment
      }
      introduction
      whatWeDid
      modularBlocks {
        ...FeaturedPointsModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageSliderModularBlockFragment
      }
      testimonialQuote
      testimonialName
      testimonialInformation
      ...LinkFragment
    }
  }
`;

export default CaseStudyPageTemplate;
